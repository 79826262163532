import { EnvironmentInjector, Injectable, inject, runInInjectionContext } from '@angular/core';
import { Firestore, QueryConstraint, collection, orderBy, query, where } from '@angular/fire/firestore';
import { Observable } from "rxjs";
import { DailyEarningsSplit, PartnerCompanyAggregate } from 'src/app/core/models/firestore.model';
import { DateHelperService } from 'src/app/shared/services/date-helper.service';
import { FirestoreHelperService, Pagination } from 'src/app/shared/services/firestore-helper.service';

@Injectable({
  providedIn: 'root'
})
export class DriverFirestoreService {
  private firestore = inject(Firestore);
  private firestoreHelper = inject(FirestoreHelperService);
  private dateHelper = inject(DateHelperService);
  private injectionContext = inject(EnvironmentInjector);

  getDailyEarningsSplits(
    driverId: string,
    filter: { startDate: string | null, endDate: string | null, companyId: string | null },
  ): Observable<Map<string, DailyEarningsSplit | null>> {
    const constraints = this.getDriverDailyEarningsQueryConstraints(filter);

    const collectionPath = `driver_aggregate/${driverId}/daily_earnings_split`;
    const colRef = collection(this.firestore, collectionPath);
    const q = query(colRef, ...constraints);

    return this.firestoreHelper.getDocumentsByQuery<DailyEarningsSplit>(q);
  }

  listDailyEarningsSplits(
    driverId: string,
    filter: { startDate: string | null, endDate: string | null, companyId: string | null },
    pagination: Pagination
  ) {
    return runInInjectionContext(this.injectionContext, () => {
      const constraints = this.getDriverDailyEarningsQueryConstraints(filter);

      const collectionPath = `driver_aggregate/${driverId}/daily_earnings_split`;
      const colRef = collection(this.firestore, collectionPath);
      const q = query(colRef, ...constraints);

      return this.firestoreHelper.getDocsPageable<DailyEarningsSplit>(q, collectionPath, pagination);
    });
  }

  watchPartnerCompanyAggregates(partnerCompanyIds: string[]) {
    return this.firestoreHelper.colDataByIdsNotNull<PartnerCompanyAggregate>(`partner_company_aggregate`, partnerCompanyIds);
  }

  private getDriverDailyEarningsQueryConstraints(
    filter: { startDate: string | null, endDate: string | null, companyId: string | null }
  ): QueryConstraint[] {
    return runInInjectionContext(this.injectionContext, () => {
      const constraints: QueryConstraint[] = [orderBy('local_date', 'desc')];
      if (filter.startDate) {
        constraints.push(where('local_date', '>=', this.dateHelper.parseDateStrToLocalDate(filter.startDate)));
      }
      if (filter.endDate) {
        constraints.push(where('local_date', '<=', this.dateHelper.parseDateStrToLocalDate(filter.endDate)));
      }
      if (filter.companyId) {
        constraints.push(where('partner_company_id', '==', filter.companyId));
      }
      return constraints;
    });
  }
}
